import React, { useState, useEffect } from "react";
import { ILottery } from "../common/interfaces";
import { useNavigate } from "react-router-dom";
import { fetchFromServer } from "../common/functions";
import {
  FaCashRegister,
  FaMoneyBill,
  FaPlay,
  FaPlus,
  FaSearch,
  FaStore,
} from "react-icons/fa";
import Paginator from "../components/paginator";
import { HiUserGroup } from "react-icons/hi";
import { Helmet } from "react-helmet";
const { DateTime } = require("luxon");

interface PaginatedResponse {
  lotteries: ILottery[];
  totalPages: number;
}

const LotteriesPage: React.FC = () => {
  const [lotteries, setLotteries] = useState<ILottery[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lotteryError, setLotteryError] = useState<{
    [lotteryId: number]: string;
  }>({});
  const navigate = useNavigate();

  const setErrorToLottery = (lotteryId: number, error: string) => {
    setLotteryError((prevError) => ({
      ...prevError,
      [lotteryId]: error, // Asigna el nuevo error
    }));
  };

  const addMoreCards = async (lotteryIndex: number) => {
    const lottery = lotteries[lotteryIndex];
    try {
      const response = await fetchFromServer(
        "POST",
        `/lotteries/${lottery.id}/addMoreCards`,
        undefined,
        "organizer",
        navigate,
      );

      const result = await response.json();

      if (response.ok) {
        const { addedQty } = result;
        setLotteries((prevLotteries) =>
          prevLotteries.map(
            (prevLottery) =>
              prevLottery.id === lottery.id
                ? { ...lottery, cardsQty: lottery.cardsQty + addedQty } // Actualiza cardsQty si coincide el id
                : prevLottery, // Deja los demás objetos sin cambios
          ),
        );
      } else {
        setErrorToLottery(lottery.id, result.message);
      }
    } catch (ex) {}
  };

  const handleDelete = (lotteryId: number) => {
    // Confirmar antes de eliminar
    if (window.confirm("¿Estás seguro de que deseas eliminar esta lotería?")) {
      const deleteLottery = async () => {
        try {
          const response = await fetchFromServer(
            "DELETE",
            `/lotteries/${lotteryId}`,
            undefined,
            "organizer",
            navigate,
          );

          const result = await response.json();

          if (response.ok) {
            setLotteries((prevLotteries) =>
              prevLotteries.filter((lottery) => lottery.id !== lotteryId),
            );
          } else {
            setErrorToLottery(lotteryId, result.message);
          }
        } catch (ex) {}
      };

      deleteLottery();
    }
  };

  const calculateLotteryState = (lottery: ILottery) => {
    if (!lottery.startDate) {
      return <span className="text-yellow-500">Cartones en Venta</span>;
    }

    if (lottery.startDate && !lottery.endDate) {
      return <span className="text-green-500">Ha comenzado</span>;
    }

    if (lottery.endDate) {
      return <span className="text-red-500">Ha terminado</span>;
    }

    return "Estado desconocido";
  };

  useEffect(() => {
    const fetchLotteries = async () => {
      try {
        const response = await fetchFromServer(
          "GET",
          `/lotteries?page=${currentPage}`,
          null,
          "organizer",
          navigate,
        );

        if (response.ok) {
          const data: PaginatedResponse = await response.json();
          setLotteries(data.lotteries);
          setTotalPages(data.totalPages);
        }
      } catch (ex) {}
      setIsLoading(false);
    };

    fetchLotteries();
  }, [currentPage]);

  const handleLogout = async () => {
    try {
      const response = await fetchFromServer(
        "POST",
        `/auth/organizer/logout`,
        undefined,
        "organizer",
        navigate,
      );

      if (response.ok) {
        navigate("/organizador/login");
        localStorage.removeItem("organizer_accessToken");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div
      className="min-h-dvh bg-gray-100"
      style={{
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
      }}
    >
      <Helmet>
        <title>Mis Loterías - Loterías Guacamaya</title>
      </Helmet>
      {/* Encabezado */}
      <header className="w-full bg-white shadow p-4 flex justify-between items-center fixed z-10">
        <h1 className="text-2xl font-bold">Mis Loterías</h1>
        <button
          onClick={handleLogout}
          className="text-red-500 hover:text-red-700 font-bold"
        >
          Salir
        </button>
      </header>

      {/* Crear lotería */}
      <div className="pt-20 px-6 mb-6 flex justify-end">
        <button
          onClick={() => navigate("/organizador/loterias/crear")}
          className="px-4 py-2 bg-green-500 text-white font-bold rounded hover:bg-green-600"
        >
          Crear Lotería
        </button>

        <button
          onClick={() => navigate("/organizador/vendedores")}
          className="px-4 py-2 ml-2 bg-green-500 text-white font-bold rounded hover:bg-green-600"
        >
          Vendedores
        </button>
      </div>

      {/* Lista de loterías */}
      <div className="px-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {!isLoading ? (
          lotteries.map((lottery, index) => (
            <div
              key={lottery.id}
              className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between relative"
            >
              {/* Información de la lotería */}
              <div className="p-4 bg-white rounded shadow">
                {/* Botón de eliminar */}
                <button
                  onClick={() => handleDelete(lottery.id)}
                  className="absolute top-6 right-6 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-sm"
                >
                  X
                </button>
                <h2 className="text-lg font-bold mb-2">{lottery.name}</h2>
                <div className="text-sm text-gray-600 space-y-1">
                  <p>
                    <strong>ID:</strong> {lottery.id}
                  </p>
                  <p>
                    <strong>Cartones Vendidos:</strong> {lottery.soldCardsQty} /{" "}
                    {lottery.cardsQty}{" "}
                    {lottery.cardsQty < 30000 && (
                      <span
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <FaPlus
                          className="cursor-pointer"
                          onClick={() => addMoreCards(index)}
                        />
                      </span>
                    )}
                  </p>
                  <p>
                    <strong>Precio del Carton:</strong> {lottery.cardPrice}{" "}
                    {lottery.currency}
                  </p>
                  <p>
                    <strong>Comision:</strong>{" "}
                    {(lottery.soldCardsQty * lottery.cardPrice * 0.05).toFixed(
                      2,
                    )}{" "}
                    {lottery.currency}
                  </p>
                  <p>
                    <strong>Pago:</strong>{" "}
                    {lottery.paid ? (
                      <span className="text-green-500">Pago recibido</span>
                    ) : (
                      <span className="text-red-500">Pago no recibido</span>
                    )}
                  </p>
                  <p>
                    <strong>Rondas Totales:</strong> {lottery.rounds.length}
                  </p>
                  <p>
                    <strong>Fecha de Sorteo:</strong>{" "}
                    {DateTime.fromISO(lottery.drawDate)
                      .setLocale("es")
                      .toFormat("EEEE, d LLL yyyy h:mm a")}
                  </p>
                  <p>
                    <strong>Estado:</strong> {calculateLotteryState(lottery)}
                  </p>
                </div>

                {/* Caja para las rondas */}
                {lottery.rounds.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-md font-semibold mb-2">Rondas</h3>
                    {lottery.rounds.map((round, index) => (
                      <p key={round.id} className="text-sm mb-2">
                        <strong>Ronda {index + 1}:</strong>{" "}
                        <span>
                          Combinación Ganadora: {round.winningCombination.name},{" "}
                        </span>
                        <span>Premio: {round.award}</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>
              {lotteryError[lottery.id] && (
                <p className="text-red-500 m-2">{lotteryError[lottery.id]}</p>
              )}
              {/* Acciones */}
              <div className="flex flex-wrap gap-2 m-2">
                <button
                  className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  onClick={() =>
                    navigate(
                      `/organizador/loteria/${lottery.id}/venderCartones`,
                      {
                        state: { lottery: lottery },
                      },
                    )
                  }
                >
                  <FaCashRegister className="w-6 h-6" />
                </button>
                <button
                  className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  onClick={() =>
                    navigate(
                      `/organizador/loteria/${lottery.id}/buscarJugador`,
                      {
                        state: { lottery: lottery },
                      },
                    )
                  }
                >
                  <FaSearch className="w-6 h-6" />
                </button>
                <button
                  className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  onClick={() =>
                    navigate(`/organizador/loteria/${lottery.id}/vendedores`, {
                      state: { lottery: lottery },
                    })
                  }
                >
                  <HiUserGroup className="w-6 h-6" />
                </button>
                <a
                  target="_blank"
                  href={`/loteria/${lottery.id}`}
                  className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  rel="noreferrer"
                >
                  <FaStore className="w-6 h-6" />
                </a>
                {!lottery.paid && (
                  <a
                    href={`https://wa.me/${process.env.REACT_APP_PLATFORM_WHATSAPP_NUMBER}?text=Hola, quiero pagar la comisión de mi lotería ID: ${lottery.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-10 h-10 bg-white text-turquoise-500 rounded-full shadow-lg flex items-center justify-center"
                  >
                    <FaMoneyBill className="w-6 h-6" />
                  </a>
                )}
                <button
                  onClick={() => navigate(`../loteria/${lottery.id}/tarima`)}
                  className="w-10 h-10 bg-white text-red-500 rounded-full shadow-lg flex items-center justify-center"
                >
                  <FaPlay className="w-6 h-6" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">Cargando...</div>
        )}
      </div>

      {/* Paginación */}
      <Paginator
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={isLoading}
        totalPages={totalPages}
      />
    </div>
  );
};

export default LotteriesPage;
