import React, { useEffect } from "react";
import { IToken } from "../../common/interfaces";
import markAnimation from "../../lottie-animations/x-marker.json";
import Lottie from "lottie-react";

interface MyComponentProps {
  tokens: IToken[];
}

const MasterCard: React.FC<MyComponentProps> = ({ tokens }) => {
  return (
    <div className="grid overflow-y-auto grid-cols-5 w-full p-2 bg-white border-gray-200">
      {Array.from({ length: Math.ceil(tokens.length / 5) }, (_, rowIndex) =>
        tokens.slice(rowIndex * 5, rowIndex * 5 + 5).map((token, index) => (
          <div
            key={index}
            className={`relative flex flex-col items-center justify-center border p-2 border-gray-400 rounded-lg`}
          >
            {/* Imagen del token */}
            <img
              src={`/tokens/${token.name}.png`}
              alt={`Ficha ${token.name}`}
              className="w-14"
            />

            {/* Nombre del token */}
            <div className="w-full text-center text-xs overflow-hidden text-ellipsis whitespace-nowrap">
              {token.name}
            </div>

            {/* Marcar ficha si existe drawnDate */}
            {token.drawnDate && (
              <Lottie
                animationData={markAnimation}
                loop={false}
                className="absolute w-[60%]"
              />
            )}
          </div>
        )),
      )}
    </div>
  );
};

export default MasterCard;
