import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ILottery, ISellCards } from "../../common/interfaces";
import { fetchFromServer, handleSharePass } from "../../common/functions";

interface MyComponentProps {
  lottery: ILottery;
}

const SellCard: React.FC<MyComponentProps> = ({ lottery }) => {
  const navigate = useNavigate();
  const [pass, setPass] = useState("");

  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    player: {
      name: "",
      email: "",
      phone: "",
      paid: false,
      payment_reference: "",
    },
    cardIds: [],
  } as ISellCards);

  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { type, name, value, checked } = e.target;

    setFormData((prev) => ({
      ...prev,
      player: {
        ...prev.player,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  const [inputValue, setInputValue] = useState("");

  const handleAddCartonId = () => {
    if (inputValue.trim()) {
      const ids = inputValue.split(/[, ]+/);

      const newCardIds = [...formData.cardIds]; // Crear una copia para actualizar

      ids.forEach((id) => {
        const newId = parseInt(id.trim(), 10);
        if (!isNaN(newId) && !newCardIds.includes(newId)) {
          newCardIds.push(newId); // Agregar al arreglo temporal
        }
      });

      if (newCardIds.length > formData.cardIds.length) {
        // Solo actualizar si hubo cambios
        setFormData((prevFormData) => ({
          ...prevFormData,
          cardIds: newCardIds,
        }));
      }

      // Vaciar inputValue después de procesar todos los IDs
      setInputValue("");
    }
  };

  const handleRemoveCartonId = (id: number) => {
    const updatedCardsIds = formData.cardIds.filter(
      (cartonId) => cartonId !== id,
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      cardIds: updatedCardsIds,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors([]);

    try {
      setSending(true);
      const response = await fetchFromServer(
        "POST",
        `/lotteries/${lottery.id}/sellCards`,
        formData,
        "organizer",
        navigate,
      );

      if (response.ok) {
        const { pass } = await response.json();
        setPass(pass);
        setFormData({
          player: {
            name: "",
            email: "",
            phone: "",
            paid: false,
            payment_reference: "",
          },
          cardIds: [],
        } as ISellCards);
      } else {
        const result = await response.json();

        const errors = Array.isArray(result.message)
          ? result.message
          : [result.message];

        setErrors(errors);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6 space-y-6">
      <div className="w-full text-center">
        <strong>{lottery.name}</strong>
      </div>
      <div className="border p-4 rounded-md">
        <label
          htmlFor="cartonIds"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Serial del Cartón
        </label>
        <input
          type="text"
          id="cartonIds"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddCartonId();
            }
          }}
          className="w-full border border-gray-300 rounded px-3 py-2 shadow-sm"
          disabled={sending}
          autoComplete="off"
        />
        <button
          type="button"
          onClick={handleAddCartonId}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 shadow-sm"
          disabled={sending}
        >
          Añadir
        </button>
        {/* Lista de Cartones */}
        <div className="flex flex-wrap gap-2 my-4">
          {formData.cardIds.map((id) => (
            <span
              key={id}
              className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full shadow-sm"
            >
              {id}
              <button
                onClick={() => handleRemoveCartonId(id)}
                className="ml-2 bg-blue-200 hover:bg-blue-300 rounded-full w-5 h-5 flex items-center justify-center text-blue-800"
              >
                &times;
              </button>
            </span>
          ))}
        </div>
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Nombre
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.player.name}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            disabled={sending}
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Correo Electrónico
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.player.email}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            disabled={sending}
          />
        </div>
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Teléfono
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.player.phone}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            disabled={sending}
          />
        </div>
        <div>
          <label
            htmlFor="paid"
            className="block text-sm font-medium text-gray-700"
          >
            Pagado
          </label>
          <input
            type="checkbox"
            id="paid"
            name="paid"
            onChange={handleChange}
            checked={formData.player.paid}
            className=""
            disabled={sending}
          />
          {formData.player.paid && (
            <input
              type="text"
              id="payment_reference"
              name="payment_reference"
              placeholder="Referencia de pago"
              value={formData.player.payment_reference}
              maxLength={100}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              disabled={sending}
            />
          )}
        </div>
      </div>
      {/* Botón Enviar y Mensajes */}
      <div className="space-y-4">
        {pass && (
          <div className="flex items-center space-x-2">
            <p className="text-green-500 text-sm">
              Cartones vendidos con éxito. El pase del jugador es: {pass}
            </p>
            <button
              onClick={() => handleSharePass(lottery, pass)}
              className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 text-sm"
            >
              Compartir
            </button>
          </div>
        )}
        {errors.map((error, index) => (
          <p key={index} className="text-red-500 text-sm">
            {error}
          </p>
        ))}
        <button
          type="submit"
          onClick={handleSubmit}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 shadow-md"
          disabled={sending}
        >
          {sending ? "Vendiendo" : "Vender"}
        </button>
      </div>
    </div>
  );
};

export default SellCard;
