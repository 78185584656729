import React, { useEffect } from "react";
import ChatWindow from "../chat_window";

interface MyComponentProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setThereAreUnseenMessages: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlayerRightColumn: React.FC<MyComponentProps> = ({
  isVisible,
  setIsVisible,
  setThereAreUnseenMessages,
}) => {
  useEffect(() => {
    if (isVisible) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
      setThereAreUnseenMessages(false);
    } else {
      // Restore scrolling
      document.body.style.overflow = "auto";
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible, setThereAreUnseenMessages]);

  return (
    <div
      className={`bg-black/50 w-full xl:bg-dodgerBlue-500 xl:w-1/4 h-full fixed top-0 right-0 z-30 flex flex-col transition-transform duration-300 p-2 ${
        isVisible || "xl:translate-x-0 translate-x-full"
      }`}
      style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
    >
      {/* Botón de cierre */}
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-2 right-2 w-8 h-8 bg-red-500 text-white rounded-full xl:hidden z-30"
      >
        ×
      </button>

      {/* Dejar un espacio en resoluciones pequenas para que se pueda ver el Visor de fichas mientras el chat esta abierto */}
      <div
        className="flex-shrink-0 flex-grow-0 basis-1/4 xl:basis-0 w-full flex items-center justify-center"
        onClick={() => setIsVisible(false)}
        onTouchEnd={() => setIsVisible(false)}
      ></div>

      <div className="flex-grow basis-3/4 xl:basis-full w-full flex items-center justify-center min-h-0">
        <ChatWindow
          isOrganizer={false}
          isPlayerRightColumnVisible={isVisible}
          setThereAreUnseenMessages={setThereAreUnseenMessages}
        />
      </div>
    </div>
  );
};

export default PlayerRightColumn;
