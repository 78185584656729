import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchFromServer } from "../common/functions";
import { Helmet } from "react-helmet";

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("organizer_accessToken")) {
      navigate("/organizador/loterias");
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setErrors(["Todos los campos son requeridos"]);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrors(["Las contrasenas no coinciden"]);
      return;
    }

    setErrors([]);
    setIsSubmitting(true);
    try {
      const { confirmPassword, ...formToSend } = formData;
      const response = await fetchFromServer("POST", `/organizers`, formToSend);

      if (response.ok) {
        navigate("/organizador/login?register=true");
      } else {
        const result = await response.json();

        if (Array.isArray(result.message)) {
          setErrors(result.message);
        } else {
          setErrors([result.message]);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className="py-6"
      style={{
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column", // Apila los elementos verticalmente
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>Registrarse como organizador - Loterías Guacamaya</title>
      </Helmet>
      {/* Div para las guacamayas */}
      <div className="w-1/2 flex items-center justify-center mb-4">
        <img
          src="/guacamayas.png"
          alt="Guacamayas"
          className="max-w-28 object-contain"
        />
      </div>

      {/* Formulario */}
      <div className="max-w-[90%] p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-4">
          ¿Quieres organizar una lotería?
        </h2>
        <h3 className="text-xl font-bold text-center mb-4">Regístrate</h3>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name Field */}
          <div className="mb-4">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Nombre"
              value={formData.name}
              onChange={handleChange}
              className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Email Field */}
          <div className="mb-4">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Phone Field */}
          <div className="mb-4">
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Teléfono"
              value={formData.phone}
              onChange={handleChange}
              className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Password Field */}
          <div className="mb-4">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Contraseña"
              value={formData.password}
              onChange={handleChange}
              className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Confirm Password Field */}
          <div className="mb-4">
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Repetir Contraseña"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="w-full mt-2 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Error Message */}
          {errors &&
            errors.map((error, index) => (
              <div
                key={index}
                className="mb-4 text-red-500 font-semibold text-center"
              >
                {error}
              </div>
            ))}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
          >
            {isSubmitting ? "Registrando..." : "Registrarme"}
          </button>
          <div
            className="text-blue-500 cursor-pointer text-center my-2"
            onClick={() => navigate("/organizador/login")}
          >
            Iniciar Sesión
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
