import React from "react";

interface MyComponentProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
}

const Paginator: React.FC<MyComponentProps> = ({
  currentPage,
  totalPages,
  setCurrentPage,
  isLoading,
}) => {
  const maxVisibleButtons = 5; // Número máximo de botones visibles (incluyendo extremos)

  const getVisiblePages = () => {
    const pages = [];

    // Siempre incluir la primera página
    if (currentPage > Math.floor(maxVisibleButtons / 2) + 1) {
      pages.push(1, "...");
    }

    // Rango de páginas visibles alrededor de la página actual
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisibleButtons / 2),
    );
    const endPage = Math.min(
      totalPages,
      currentPage + Math.floor(maxVisibleButtons / 2),
    );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Siempre incluir la última página
    if (currentPage < totalPages - Math.floor(maxVisibleButtons / 2)) {
      pages.push("...", totalPages);
    }

    return pages;
  };

  return (
    <div className="flex justify-center items-center gap-2 p-2 overflow-hidden">
      {/* Botón Anterior */}
      <button
        disabled={isLoading || currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50 flex-shrink-0"
      >
        Anterior
      </button>

      {/* Números de página */}
      {getVisiblePages().map((page, index) =>
        typeof page === "number" ? (
          <button
            key={index}
            disabled={isLoading}
            onClick={() => setCurrentPage(page)}
            className={`px-4 py-2 rounded text-center flex-shrink min-w-[40px] ${
              currentPage === page
                ? "bg-blue-500 text-white"
                : "bg-gray-300 hover:bg-gray-400"
            }`}
          >
            {page}
          </button>
        ) : (
          <span
            key={index}
            className="px-4 py-2 text-gray-500 text-center flex-shrink min-w-[40px]"
          >
            {page}
          </span>
        ),
      )}

      {/* Botón Siguiente */}
      <button
        disabled={isLoading || currentPage >= totalPages}
        onClick={() => setCurrentPage((prev) => prev + 1)}
        className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50 flex-shrink-0"
      >
        Siguiente
      </button>
    </div>
  );
};

export default Paginator;
