import React from "react";
const UnderConstructionPage: React.FC = () => {
  return (
    <div
      className="h-dvh"
      style={{
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
        display: "flex",
        flexDirection: "column", // Apila los elementos verticalmente
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Div para las guacamayas */}
      <div className="w-1/2 flex items-center justify-center mb-4">
        <img
          src="/guacamayas.png"
          alt="Guacamayas"
          className="max-w-28 object-contain"
        />
        Esta pagina esta en construccion.
      </div>
    </div>
  );
};

export default UnderConstructionPage;
