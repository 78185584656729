import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Role } from "../common/types";

interface MyComponentProps {
  role: "player" | "organizer";
}

const Auth: React.FC<MyComponentProps> = ({ role }) => {
  const { lotteryId } = useParams();
  const [userId, setUserId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const readTokenAndCheck = (role: Role) => {
      const token_name = role + "_accessToken";
      // Leer el token desde localStorage
      const token = localStorage.getItem(token_name);

      if (!token) {
        return false;
      }

      try {
        const jwtPayload: any = jwtDecode(token);

        if (jwtPayload && role === "player") {
          if (jwtPayload.lotteryId !== +lotteryId!) {
            localStorage.removeItem(token_name);
            return false;
          }
        }

        return jwtPayload.role === role ? jwtPayload : false;
      } catch (error) {
        localStorage.removeItem(token_name);
        return false;
      }
    };

    const { sub: userId } = readTokenAndCheck(role);
    if (!userId) {
      navigate(
        role === "player"
          ? `/loteria/${lotteryId}/entrar`
          : `/organizador/login`,
        { replace: true },
      );
    } else {
      setUserId(userId);
    }
  });

  if (!userId) {
    return null;
  }

  return <Outlet context={{ userId }} />;
};

export default Auth;
