import React, { createContext, useContext, useEffect, useState } from "react";
import socket from "../../socket"; // Instancia única de socket
import { WsConnectionStatus } from "../types";

interface SocketContextType {
  socket: typeof socket; // Tipo de la instancia de socket
  connectionStatus: WsConnectionStatus;
}

// Crear el contexto
const SocketContext = createContext<SocketContextType | undefined>(undefined);

// Proveedor del contexto
export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [connectionStatus, setConnectionStatus] =
    useState<WsConnectionStatus>("disconnected");

  useEffect(() => {
    // Registrar listeners una sola vez
    socket.on("connect", () => {
      console.log("Conectado al servidor");
      setConnectionStatus("connected");
    });

    socket.on("disconnect", () => {
      console.log("Desconectado del servidor");
      setConnectionStatus("disconnected");
    });

    socket.on("connect_error", (error) => {
      console.error("Error al conectar al servidor:", error);
      setConnectionStatus("disconnected");
    });

    // Limpieza: Aunque no eliminamos los listeners aquí, puedes hacerlo si es necesario
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, connectionStatus }}>
      {children}
    </SocketContext.Provider>
  );
};

// Hook para usar el contexto
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket debe ser usado dentro de un SocketProvider");
  }
  return context;
};
