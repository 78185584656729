import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../lottie-animations/loading.json";
interface MyComponentProps {}

const Loading: React.FC<MyComponentProps> = ({}) => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center">
      <Lottie
        animationData={loadingAnimation}
        loop={true}
        className="w-52 object-contain"
      />
      <p>Cargando...</p>
    </div>
  );
};

export default Loading;
