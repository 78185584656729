import React from "react";
import { IDrawnTokenEvent, IRound } from "../common/interfaces";
import Card from "./card";

interface MyComponentProps {
  lastDrawnTokenEvent: IDrawnTokenEvent;
  currentRound: IRound;
  nextRound: () => void;
  setIsCardWinner: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const CardWinnersAnimation: React.FC<MyComponentProps> = ({
  lastDrawnTokenEvent,
  currentRound,
  nextRound,
  setIsCardWinner,
}) => {
  return (
    <div
      className="fixed flex flex-col bg-black z-50 overflow-y-scroll"
      style={{
        height: "100vh",
        width: "100vw",
        padding: "16px",
        color: "white",
      }}
    >
      <button
        onClick={() => {
          nextRound();
          setIsCardWinner(undefined);
        }}
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg z-10"
      >
        Cerrar
      </button>

      <div className="text-center mt-12">
        <div className="text-white text-xl md:text-4xl font-bold">
          <h1>¡Han cantado lotería!</h1>
          {lastDrawnTokenEvent.winners.length > 1 ? (
            <div>Los ganadores son</div>
          ) : (
            <div>El ganador es</div>
          )}
        </div>

        <div className="mt-2 rounded-lg overflow-y-auto">
          {lastDrawnTokenEvent.winners.map((winner) => (
            <div key={winner.id} className="my-4">
              <p className="text-white text-lg">{winner.name}</p>
              <Card
                card={winner.cards[0]}
                showSerial={true}
                drawnTokens={currentRound.drawnTokens}
                winningCombination={currentRound.winningCombination.combination}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardWinnersAnimation;
