import React, { useEffect, useRef, useState } from "react";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { IChatMessage } from "../common/interfaces";
import { useSocket } from "../common/contexts/useSocket";

interface MyComponentProps {
  isOrganizer: boolean;
  setThereAreUnseenMessages?: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayerRightColumnVisible?: boolean;
}

const ChatWindow: React.FC<MyComponentProps> = ({
  isOrganizer,
  setThereAreUnseenMessages,
  isPlayerRightColumnVisible,
}) => {
  const { socket, connectionStatus } = useSocket();
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [input, setInput] = useState<string>("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageAreaRef = useRef<HTMLDivElement>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [showNewMessagesIndicator, setShowNewMessagesIndicator] =
    useState(false);
  const [connectedPlayersQty, setConnectedPlayersQty] = useState(0);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    setAutoScroll(true);
    setShowNewMessagesIndicator(false);
  };

  const handleScroll = () => {
    if (!messageAreaRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = messageAreaRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10; // Ajustar tolerancia si es necesario

    if (isAtBottom) {
      setAutoScroll(true);
      setShowNewMessagesIndicator(false);
    } else {
      setAutoScroll(false);
    }
  };

  const sendMessage = () => {
    if (input.trim()) {
      const newMessage: IChatMessage = {
        senderName: "Yo",
        text: input,
        isOrganizer: isOrganizer,
      };
      setMessages([...messages, newMessage]);
      socket.emit("newChatMessage", { message: input });
      setInput("");
    }
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    const onNewChatMessage = (newChatMessage: IChatMessage) => {
      setMessages((prevMessages) => [...prevMessages, newChatMessage]);
      if (setThereAreUnseenMessages) {
        if (!isPlayerRightColumnVisible) {
          setThereAreUnseenMessages(true);
        }
      }
    };

    socket.on("newChatMessage", onNewChatMessage);

    const onConnectedPlayersQty = (data: { connectedPlayersQty: number }) => {
      setConnectedPlayersQty(data.connectedPlayersQty);
    };

    socket.on("connectedPlayersQty", onConnectedPlayersQty);

    return () => {
      socket.off("newChatMessage", onNewChatMessage);
      socket.off("connectedPlayersQty", onConnectedPlayersQty);
    };
  }, [isPlayerRightColumnVisible]);

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom();
    } else {
      setShowNewMessagesIndicator(true);
    }
  }, [messages]);

  const handleEmojiClick = (emojiObject: EmojiClickData) => {
    setInput(input + emojiObject.emoji);
  };

  return (
    <div className="flex flex-col w-full h-full border rounded-lg shadow-lg bg-white relative">
      {/* Header */}
      <div
        className="h-[50px] text-white p-3 rounded-t-lg text-center"
        style={{ backgroundColor: "#00CED1" }}
      >
        Chat{" "}
        {connectionStatus === "connected"
          ? `(${connectedPlayersQty} conectados)`
          : `(Desconectado)`}
      </div>

      {/* Message Area */}
      <div
        ref={messageAreaRef}
        className="flex-1 overflow-y-auto p-3 space-y-2"
        onScroll={handleScroll}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`p-2 rounded-lg text-sm self-start ${message.isOrganizer ? "bg-yellow-500" : message.senderName === "Yo" ? "bg-gray-400 text-white" : "bg-gray-200"}`}
          >
            {message.senderName ? message.senderName + ": " : ""} {message.text}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* New Messages Indicator */}
      {showNewMessagesIndicator && (
        <div
          onClick={scrollToBottom}
          className="absolute bottom-20 right-4 bg-turquoise-500 text-white px-3 py-1 rounded-lg cursor-pointer shadow-lg"
        >
          Nuevos mensajes
        </div>
      )}

      {/* Emoji Picker */}
      {showEmojiPicker && (
        <div className="absolute bottom-12 left-0 w-full h-84 bg-white border rounded-lg shadow-lg z-10">
          <EmojiPicker onEmojiClick={handleEmojiClick} width="100%" />
        </div>
      )}

      {/* Input Area */}
      <div className="h-[50px] flex items-center border-t p-2">
        <button
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          className="text-gray-500 hover:text-gray-700 focus:outline-none mr-2"
        >
          😀
        </button>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="flex-1 w-[60%] border rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring focus:ring-blue-300"
          placeholder="Escribe un mensaje..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
        />
        <button
          onClick={sendMessage}
          disabled={connectionStatus !== "connected"}
          className="ml-2 bg-turquoise-500 text-white px-4 py-2 rounded-lg hover:bg-customBrown-600 focus:outline-none"
        >
          Enviar
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
