import React, { useEffect, useRef, useState } from "react";
import { CiLight } from "react-icons/ci";
import { FaClipboardList, FaEnvelope, FaInfoCircle } from "react-icons/fa";
import NoSleep from "nosleep.js";
interface MyComponentProps {
  openLeftColumn: () => void;
  openRightColumn: () => void;
  isOrganizer: boolean;
  thereAreUnseenMessages?: boolean;
  className?: string;
}

const MobileButtons: React.FC<MyComponentProps> = ({
  openLeftColumn,
  openRightColumn,
  isOrganizer,
  thereAreUnseenMessages,
  className = "",
}) => {
  const [isAwake, setIsAwake] = useState(false);
  const noSleep = useRef(new NoSleep());

  const toggleNoSleep = () => {
    if (isAwake) {
      noSleep.current.disable();
    } else {
      document.addEventListener(
        "click",
        function enableNoSleep() {
          document.removeEventListener("click", enableNoSleep, false);
          noSleep.current.enable();
        },
        false,
      );
    }
    setIsAwake(!isAwake);
  };

  useEffect(() => {
    if (isAwake) {
      return () => noSleep.current.disable();
    }
  }, []);

  return (
    <div className={`${className}`}>
      <button
        onClick={() => {
          toggleNoSleep();
        }}
        className={`w-12 h-12 ${isAwake ? "bg-yellow-300 text-white" : "text-black"}  rounded-full shadow-lg flex items-center justify-center`}
      >
        <CiLight className="w-6 h-6" />
      </button>
      <button
        className="w-12 h-12 bg-turquoise-900 text-white rounded-full shadow-lg flex items-center justify-center"
        onClick={openLeftColumn}
      >
        <FaInfoCircle className="w-6 h-6" />
      </button>
      <div className="relative">
        <button
          className="w-12 h-12 bg-white text-turquoise-900 rounded-full shadow-lg flex items-center justify-center"
          onClick={openRightColumn}
        >
          {isOrganizer ? (
            <FaClipboardList className="w-6 h-6" />
          ) : (
            <FaEnvelope className="w-6 h-6" />
          )}
        </button>
        {!isOrganizer && thereAreUnseenMessages && (
          <div className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border border-white"></div>
        )}
      </div>
    </div>
  );
};

export default MobileButtons;
