import React from "react";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <div className="m-2">
      <div className="flex justify-center gap-6 my-4">
        <a
          href="https://facebook.com/profile.php?id=61571198430614"
          target="_blank"
          rel="noopener noreferrer"
          className="text-2xl"
          style={{ color: "#0866ff" }}
        >
          <FaFacebook />
        </a>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-2xl"
          style={{ color: "#fe1b81" }}
        >
          <FaInstagram />
        </a>
        <a
          href="https://tiktok.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-2xl"
          style={{ color: "black" }}
        >
          <FaTiktok />
        </a>
      </div>
      <div className="w-full text-center text-white">Preguntas Frecuentes</div>
      <div className="w-full text-center text-white">
        Política de Privacidad
      </div>
      <div className="w-full text-center text-white">
        Términos y Condiciones
      </div>
    </div>
  );
};

export default Footer;
