import React, { useEffect, useState } from "react";
import {
  IDrawnTokenEvent,
  IEarlyPayWinnerPlayer,
  ILottery,
  IRound,
  ISerialWinnerPlayer,
  IToken,
} from "../common/interfaces";
import SerialWinnerAnimation from "../components/serial-winner-animation";
import Loading from "../components/loading";
import {
  fetchFromServer,
  preloadTokenImages,
  speak,
} from "../common/functions";
import { useNavigate, useParams } from "react-router-dom";
import MobileButtons from "../components/mobile_buttons";
import LotteryInfoColumn from "../components/lottery-info.column";
import Drawer from "../components/organizer/drawer";
import CardWinnersAnimation from "../components/card-winners-animation";
import ChatWindow from "../components/chat_window";
import OrganizerRightColumn from "../components/organizer/organizer-right-column";
import { useSocket } from "../common/contexts/useSocket";
import { Helmet } from "react-helmet";
import EarlyPayWinnerAnimation from "../components/earlypay-winner-animation";

const StagePage: React.FC = () => {
  const { lotteryId } = useParams();
  const [allTokens, setAllTokens] = useState<IToken[]>([]);
  const [isLeftColumnVisible, setisLeftColumnVisible] = useState(false);
  const [isRightColumnVisible, setisRightColumnVisible] = useState(false);
  const [lottery, setLottery] = useState<ILottery>();
  const [currentRound, setCurrentRound] = useState<IRound>();
  const [areCardWinners, setAreCardWinners] = useState<boolean>();
  const [serialWinnerJustDrawn, setSerialWinnerJustDrawn] =
    useState<boolean>(false);
  const [earlyPayWinnerJustDrawn, setEarlyPayWinnerJustDrawn] =
    useState<boolean>(false);
  const { socket, connectionStatus } = useSocket();
  const [lastDrawnTokenEvent, setLastDrawnTokenEvent] =
    useState<IDrawnTokenEvent>();
  const navigate = useNavigate();

  const openLeftColumn = () => {
    setisLeftColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  };

  const openRightColumn = () => {
    setisRightColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  };

  const fetchAllTokens = async () => {
    const response = await fetchFromServer("GET", `/tokens/all`);

    if (!response.ok) {
      throw new Error("Error en la solicitud");
    }

    const tokens: IToken[] = await response.json();

    return tokens;
  };

  const fetchLottery = async () => {
    const response = await fetchFromServer("GET", `/lotteries/${lotteryId}`);

    if (!response.ok) {
      navigate("/", { replace: true });
    } else {
      const lottery: ILottery = await response.json();
      return lottery;
    }
  };

  const handleStart = () => {
    socket.emit("start");
    console.log("Listeners for 'error':", socket.listeners("error").length);
  };

  const nextRound = () => {
    // Actualizamos las victorias en el round y el currentRoundId.
    setLottery((prevLottery) => {
      if (prevLottery) {
        const rounds = prevLottery.rounds!;
        const currentIndex = rounds.findIndex(
          (round) => round.id === currentRound?.id,
        );

        const updatedRounds = prevLottery.rounds.map((round) =>
          round.id === lastDrawnTokenEvent?.roundId
            ? { ...round, victories: lastDrawnTokenEvent.winners }
            : round,
        );

        const newCurrentRoundId =
          currentIndex !== -1 && currentIndex < rounds.length - 1
            ? rounds[currentIndex + 1].id
            : undefined;

        const newCurrentRound = prevLottery.rounds.find(
          (round) => round.id === newCurrentRoundId,
        );
        setCurrentRound(newCurrentRound);

        setAllTokens((prevAllTokens) => {
          const updatedTokens: IToken[] = prevAllTokens.map((token) => ({
            ...token,
            drawnDate: undefined,
          }));
          return updatedTokens;
        });

        return {
          ...prevLottery,
          currentRoundId: newCurrentRoundId,
          rounds: updatedRounds,
        };
      }
    });

    setLastDrawnTokenEvent(undefined);
  };

  useEffect(() => {
    const onStart = async (data: any) => {
      const { startDate } = data;

      setLottery((prevLottery) => {
        if (prevLottery) {
          const newCurrentRound = prevLottery.rounds.find(
            (round) => round.victories.length === 0,
          );
          setCurrentRound(newCurrentRound);

          if (prevLottery) {
            return {
              ...prevLottery,
              startDate: startDate,
              currentRoundId: newCurrentRound?.id,
            };
          }
        }

        return prevLottery; // En caso de que sea undefined, devolvemos el estado previo
      });
      speak("La lotería ha comenzado.");
    };

    const onTokenDrawn = async (drawnTokenEvent: IDrawnTokenEvent) => {
      // Actualizamos los drawnTokens del round y los winners.
      setLottery((prevLottery) => {
        if (prevLottery) {
          const updatedRounds = prevLottery.rounds.map((round) =>
            round.id === drawnTokenEvent.roundId
              ? {
                  ...round,
                  drawnTokens: drawnTokenEvent.drawnTokens,
                  victories: drawnTokenEvent.winners,
                }
              : round,
          );

          const _currentRound = updatedRounds.find(
            (round) => round.id === drawnTokenEvent.roundId,
          )!;
          setCurrentRound(_currentRound);

          const drawnTokensMap = new Map(
            _currentRound.drawnTokens.map((token) => [
              token.id,
              token.drawnDate,
            ]),
          );

          setAllTokens((prevAllTokens) => {
            const updatedTokens: IToken[] = prevAllTokens.map((token) => ({
              ...token,
              drawnDate: drawnTokensMap.get(token.id) || token.drawnDate,
            }));
            return updatedTokens;
          });

          if (drawnTokenEvent.winners.length > 0) {
            setAreCardWinners(true);
          }

          return {
            ...prevLottery,
            rounds: updatedRounds,
            endDate: drawnTokenEvent.endDate
              ? drawnTokenEvent.endDate
              : prevLottery.endDate,
          };
        }
      });

      setLastDrawnTokenEvent(drawnTokenEvent);
    };

    const onSerialWinner = async (serialWinnerPlayer: ISerialWinnerPlayer) => {
      // Actualizamos el ganador de Serial Ganador.
      setLottery((prevLottery) => {
        if (prevLottery) {
          setSerialWinnerJustDrawn(true);
          return {
            ...prevLottery,
            cardSerialWinner: serialWinnerPlayer,
          };
        }
      });
    };

    const onEarlyPayWinner = async (
      earlyPayWinnerPlayer: IEarlyPayWinnerPlayer,
    ) => {
      // Actualizamos el ganador de Serial Ganador.
      setLottery((prevLottery) => {
        if (prevLottery) {
          setEarlyPayWinnerJustDrawn(true);
          return {
            ...prevLottery,
            earlyPayDrawWinner: earlyPayWinnerPlayer,
          };
        }
      });
    };

    const onError = async (message: string) => {
      alert(message);
    };

    const mountGame = async () => {
      const _allTokens = await fetchAllTokens();
      await preloadTokenImages(_allTokens);
      setAllTokens(_allTokens);

      const _lottery = await fetchLottery();
      if (_lottery) {
        const _currentRound = _lottery.rounds.find(
          (round) => round.id === _lottery.currentRoundId,
        );
        setCurrentRound(_currentRound);
        setLottery(_lottery);

        const drawnTokensMap = new Map(
          _currentRound?.drawnTokens.map((token) => [
            token.id,
            token.drawnDate,
          ]),
        );

        setAllTokens(() => {
          const updatedTokens: IToken[] = _allTokens.map((token) => ({
            ...token,
            drawnDate: drawnTokensMap.get(token.id) || token.drawnDate,
          }));
          return updatedTokens;
        });

        if (_lottery.live) {
          socket.on("start", onStart);
          socket.on("serialWinner", onSerialWinner);
          socket.on("earlyPayWinner", onEarlyPayWinner);
          socket.on("tokenDrawn", onTokenDrawn);
          socket.on("error", onError);
          socket.io.opts.query = { lotteryId: _lottery.id };
          socket.auth = {
            accessToken: localStorage.getItem("organizer_accessToken"),
          };
          socket.connect();
        }
      }
    };

    mountGame();

    return () => {
      socket.disconnect();
      socket.off("start", onStart);
      socket.off("serialWinner", onSerialWinner);
      socket.off("earlyPayWinner", onEarlyPayWinner);
      socket.off("tokenDrawn", onTokenDrawn);
      socket.off("error", onError);
    };
  }, []);

  useEffect(() => {
    // Agregar una entrada inicial al historial
    window.history.pushState(null, "", window.location.href);

    const handlePopState = () => {
      // Si no hay columnas abiertas, rehacer el estado actual
      if (!isLeftColumnVisible && !isRightColumnVisible) {
        window.history.pushState(null, "", window.location.href);
        return;
      }

      // Si hay una columna abierta, replegarla
      if (isLeftColumnVisible) {
        setisLeftColumnVisible(false);
      } else if (isRightColumnVisible) {
        setisRightColumnVisible(false);
      }
    };

    // Escucha el evento de retroceso del historial
    window.addEventListener("popstate", handlePopState);

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isLeftColumnVisible, isRightColumnVisible]);

  if (!lottery) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-dvh">
      <Helmet>
        <title>
          Tarima de Lotería {lottery.name} - Impulsado por Guacamayalote.online
        </title>
      </Helmet>
      {serialWinnerJustDrawn && (
        <SerialWinnerAnimation serialWinnerPlayer={lottery.cardSerialWinner} />
      )}
      {earlyPayWinnerJustDrawn && (
        <EarlyPayWinnerAnimation
          earlyPayWinnerPlayer={lottery.earlyPayDrawWinner}
          earlyPayAward={lottery.earlyPayDrawAward}
        />
      )}
      {areCardWinners && (
        <CardWinnersAnimation
          lastDrawnTokenEvent={lastDrawnTokenEvent!}
          currentRound={currentRound!}
          nextRound={nextRound}
          setIsCardWinner={setAreCardWinners}
        />
      )}

      {/* Botones flotantes para móviles */}
      <MobileButtons
        openLeftColumn={openLeftColumn}
        openRightColumn={openRightColumn}
        isOrganizer={true}
        className={`xl:hidden fixed bottom-4 right-4 z-30 flex flex-col space-y-2 transition-opacity duration-300 ${
          !isLeftColumnVisible && !isRightColumnVisible
            ? "opacity-100 visible"
            : "opacity-0 invisible"
        }`}
      />

      {/* Estructura de columnas */}
      <div className="flex flex-1 flex-row min-h-0">
        {/* Columna izquierda */}
        <LotteryInfoColumn
          lottery={lottery}
          currentRound={currentRound}
          isVisible={isLeftColumnVisible}
          setIsVisible={setisLeftColumnVisible}
        />

        {/* Contenido central */}
        <div
          className="w-full relative xl:w-1/2 xl:ml-[25%] flex flex-col min-h-0"
          style={{
            background: "linear-gradient(to right, #33FCFF, #5CADFF)", // Gradiente
          }}
        >
          {/* Fila superior fija dentro de la columna central */}
          <div
            className="sticky top-0 w-full text-white p-2 mb-4 z-10"
            style={{
              background: "linear-gradient(to right, #00CED1, #1E90FF)", // Gradiente
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="flex p-1 justify-between items-center">
              <button
                onClick={() => navigate("/organizador/loterias")}
                className="text-green-200 hover:text-green-700 font-bold"
              >
                Atras
              </button>
              <span>{lottery.organizer.name}</span>
            </div>
            <Drawer
              lottery={lottery}
              tokens={allTokens}
              lastDrawnTokenEvent={lastDrawnTokenEvent}
            />
            {!lottery.startDate && (
              <div className="flex justify-center mt-4">
                <button
                  className="px-6 py-2 text-white font-bold rounded"
                  style={{ backgroundColor: "#00CED1" }}
                  onClick={(e) => {
                    (e.target as HTMLButtonElement).style.display = "none";
                    handleStart();
                  }}
                >
                  Empezar Lotería
                </button>
              </div>
            )}
          </div>

          {/* Contenido principal */}
          <div className="flex-1 flex justify-center items-center p-2 min-h-0">
            <ChatWindow isOrganizer={true} />
          </div>
        </div>

        {/* Columna derecha */}
        <OrganizerRightColumn
          isVisible={isRightColumnVisible}
          setIsVisible={setisRightColumnVisible}
          tokens={allTokens}
        />
      </div>
    </div>
  );
};

export default StagePage;
