import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFromServer, speak } from "../common/functions";
import { ILottery } from "../common/interfaces";
import Loading from "../components/loading";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const LotteryEnterPage: React.FC = () => {
  const { lotteryId, passFromUrl } = useParams();
  const [lottery, setLottery] = useState<ILottery>();
  const [pass, setPass] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("player_accessToken")) {
      navigate(`/jugador/loteria/${lotteryId}/mesa`);
    } else {
      const fetchLottery = async () => {
        const response = await fetchFromServer(
          "GET",
          `/lotteries/${lotteryId}`,
        );
        const _lottery: ILottery = await response.json();

        if (response.ok) {
          setLottery(_lottery);

          if (passFromUrl) {
            setPass(passFromUrl);
          }
        } else {
          navigate("/", { replace: true });
        }
      };
      fetchLottery();
    }
  }, [lotteryId, passFromUrl]);

  const handleSubmit = async () => {
    setButtonDisabled(true);
    setErrors([]);
    if (pass.length < 10) {
      setErrors(["El pase debe tener al menos 10 caracteres"]);
    } else {
      const response = await fetchFromServer(
        "POST",
        `/auth/lottery/${lotteryId}/enter`,
        { pass: pass },
      );

      const result = await response.json();

      if (response.ok) {
        localStorage.setItem("player_accessToken", result.accessToken);
        navigate(`/jugador/loteria/${lotteryId}/mesa`, { replace: true });
      } else {
        if (Array.isArray(result.message)) {
          setErrors(result.message);
        } else {
          setErrors([result.message]);
        }
      }
    }
    setButtonDisabled(false);
  };

  if (!lottery) {
    return <Loading />;
  }

  return (
    <div
      className="h-dvh"
      style={{
        background: "linear-gradient(to right, #33FCFF, #5CADFF)",
        display: "flex",
        flexDirection: "column", // Apila los elementos verticalmente
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>
          Entrar a Lotería {lottery.name} - Impulsado por Guacamayalote.online
        </title>
      </Helmet>
      {/* Div para las guacamayas */}
      <div className="w-1/2 flex items-center justify-center mb-4">
        <img
          src="/guacamayas.png"
          alt="Guacamayas"
          className="max-w-28 object-contain"
        />
      </div>

      {/* Formulario */}
      <div className="w-[90%] md:w-[50%] lg:w-[30%] p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-center mb-2">Lotería</h1>
        <h2 className="text-2x1 font-bold text-center mb-2">{lottery.name}</h2>
        <form className="space-y-4">
          {/* Campo de Pase */}
          <div className="">
            <input
              type="text"
              id="pase"
              name="pase"
              placeholder="Introduzca su pase"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Error Messages */}
          {errors &&
            errors.map((error, index) => (
              <div
                key={index}
                className="mb-4 text-red-500 font-semibold text-center"
              >
                {error}
              </div>
            ))}

          {/* Botón de Entrada */}
          <button
            type="button"
            onClick={async () => {
              // Aprovechamos esta interaccion del usuario para poder tener permisos para
              // SpeechSynthesisUtterance. El cuerpo de la funcion tiene que ir directamente en el boton para que speak() funcione.
              speak("Bienvenido");
              handleSubmit();
            }}
            className="w-full p-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={buttonDisabled}
          >
            Entrar
          </button>
        </form>
        <div
          className="w-full mt-2 text-center text-blue-500 cursor-pointer"
          onClick={() => navigate("/organizador/login")}
        >
          Entrar como organizador
        </div>
        <span className="text-xs text-yellow-600 mt-36">Una conexión de internet lenta puede causar retrasos y desincronización entre su dispositivo y el servidor, sin embargo, a nivel de servidor su cartón siempre estará sincronizado y no existe riesgo de que se pase la lotería.</span>
      </div>
      <Footer />
    </div>
  );
};

export default LotteryEnterPage;
