import React, { useEffect, useState } from "react";
import { IEarlyPayWinnerPlayer } from "../common/interfaces";
import winnerAnimation from "../lottie-animations/winner.json";
import { speak } from "../common/functions";
import Lottie from "lottie-react";

interface MyComponentProps {
  playerId?: number;
  earlyPayWinnerPlayer: IEarlyPayWinnerPlayer;
  earlyPayAward: string;
}

const EarlyPayWinnerAnimation: React.FC<MyComponentProps> = ({
  playerId,
  earlyPayWinnerPlayer,
  earlyPayAward,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible) {
      if (earlyPayWinnerPlayer.id === playerId) {
        speak(`Felicidades, has ganado el sorteo Pronto Pago`);
      } else {
        speak(`${earlyPayWinnerPlayer.name} ha ganado el sorteo Pronto Pago`);
      }
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div
      className="fixed flex overflow-y-auto flex-col items-center justify-center bg-black z-50"
      style={{ height: "100vh", width: "100vw" }}
    >
      {/* Botón de cerrar */}
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg z-10"
      >
        Cerrar
      </button>

      {/* Animación Lottie */}

      {/* Texto centrado arriba de la animación */}
      <div className="text-center mt-4 px-4">
        <h1 className="text-white text-2xl md:text-4xl font-bold">
          {earlyPayWinnerPlayer.id === playerId && (
            <span className="text-yellow-500">
              ¡Has Ganado el sorteo Pronto Pago!
            </span>
          )}
        </h1>
        {earlyPayWinnerPlayer.id !== playerId && (
          <p className="bg-white text-black mt-2 px-2 py-1 rounded-md">
            {earlyPayWinnerPlayer.name} ha ganado el sorteo Pronto Pago
          </p>
        )}
      </div>
      {earlyPayWinnerPlayer.id === playerId && (
        <div className="text-center">
          <h2 className="text-white text-xl md:text-3xl font-bold p-2 animate-growShrink">
            {earlyPayAward}
          </h2>
          {/* Animación Lottie */}
          <Lottie
            animationData={winnerAnimation}
            loop={true}
            className="w-full h-full max-h-[70%] object-contain"
          />
          <p className="mt-2 text-white text-sm md:text-lg px-4">
            Contacta al organizador...
          </p>
        </div>
      )}
    </div>
  );
};

export default EarlyPayWinnerAnimation;
