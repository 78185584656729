import React, { useState, useRef, useEffect } from "react";
import { IDrawnTokenEvent, IRound, IToken } from "../../common/interfaces";
import { speak } from "../../common/functions";
import fireAnimation from "../../lottie-animations/hot.json";
import Lottie from "lottie-react";

interface MyComponentProps {
  playerId: number;
  tokens: IToken[];
  lastDrawnTokenEvent?: IDrawnTokenEvent;
  currentRound?: IRound;
  setIsCardWinner: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const DrawerVisor: React.FC<MyComponentProps> = ({
  playerId,
  tokens,
  lastDrawnTokenEvent,
  currentRound,
  setIsCardWinner,
}) => {
  const [currentRandomToken, setCurrentRandomToken] = useState<IToken | null>(
    null,
  );
  const shufflerRef = useRef<NodeJS.Timeout | null>(null);

  const speech = (drawnTokenEvent: IDrawnTokenEvent) => {
    if ("speechSynthesis" in window) {
      const lastDrawnToken = drawnTokenEvent.drawnTokens.at(-1);

      if (lastDrawnToken) {
        var speech = lastDrawnToken.name;

        if (drawnTokenEvent.winners.length > 0) {
          speech += ", y han cantado lotería. ";

          const IAmWinner = drawnTokenEvent.winners.some(
            (winner) => winner.id === playerId,
          );

          if (drawnTokenEvent.winners.length === 1) {
            if (IAmWinner) {
              speech +=
                "Felicidades, has ganado con el cartón número " +
                drawnTokenEvent.winners[0].cards[0].serial +
                ". ";
            } else {
              speech +=
                drawnTokenEvent.winners[0].name +
                " ha ganado con el cartón número " +
                drawnTokenEvent.winners[0].cards[0].serial +
                ". ";
            }
          } else if (drawnTokenEvent.winners.length > 1) {
            if (IAmWinner) {
              speech +=
                "Felicidades, has ganado con el cartón número " +
                drawnTokenEvent.winners[0].cards[0].serial +
                ". ";
              speech +=
                "Tambien hay otros " +
                (drawnTokenEvent.winners.length - 1) +
                " ganadores. ";
            } else {
              speech +=
                "Hay " + drawnTokenEvent.winners.length + " ganadores. ";
            }

            for (var i = 0; i < drawnTokenEvent.winners.length - 1; i++) {
              speech +=
                drawnTokenEvent.winners[i].name +
                " ha ganado con el cartón número " +
                drawnTokenEvent.winners[i].cards[0].serial +
                ", ";
            }
            speech +=
              "y " +
              drawnTokenEvent.winners.at(-1)?.name +
              " ha ganado con el cartón número " +
              drawnTokenEvent.winners.at(-1)?.cards[0].serial +
              ". ";
          }

          if (drawnTokenEvent.endDate) {
            speech += "La lotería ha terminado.";
          }
        }
        speak(speech);
      }
    }
  };

  const startFakeShuffler = () => {
    if (!shufflerRef.current) {
      shufflerRef.current = setInterval(() => {
        const notDrawnTokens = tokens.filter(
          (token) =>
            !currentRound?.drawnTokens.some(
              (drawnToken) => drawnToken.id === token.id,
            ),
        );
        const randomIndex = Math.floor(Math.random() * notDrawnTokens.length);
        setCurrentRandomToken(notDrawnTokens[randomIndex]);
      }, 100);
    }
  };

  const stopFakeShuffler = () => {
    if (shufflerRef.current) {
      clearInterval(shufflerRef.current);
      shufflerRef.current = null;
      setCurrentRandomToken(null);
    }
  };

  useEffect(() => {
    if (lastDrawnTokenEvent) {
      // Ejecutar startShuffler
      startFakeShuffler();

      // Ejecutar stopShuffler después de 2 segundos
      const timeoutId = setTimeout(() => {
        stopFakeShuffler();

        speech(lastDrawnTokenEvent!);

        if (lastDrawnTokenEvent.winners.length > 0) {
          setTimeout(() => {
            const IAmWinner = lastDrawnTokenEvent.winners.some(
              (winner) => winner.id === playerId,
            );
            if (IAmWinner) {
              setIsCardWinner(true);
            } else {
              setIsCardWinner(false);
            }
          }, 1000);
        }
      }, 2000);

      // Limpieza para evitar efectos acumulativos
      return () => clearTimeout(timeoutId);
    }
  }, [lastDrawnTokenEvent]);

  return (
    <div className="flex flex-col">
      {/* Fila superior */}
      <div className="flex w-full">
        {/* Columna 1 */}
        <div className="w-1/2 flex items-center justify-center">
          <img
            src="/guacamayas.png"
            alt="Guacamayas"
            className="max-w-28 object-contain"
          />
        </div>

        {/* Columna 2 */}
        <div className="w-1/2 flex items-center justify-center border-red-700 border-2 rounded-lg bg-white p-2">
          {(currentRandomToken || lastDrawnTokenEvent?.drawnTokens.at(-1)) && (
            <div>
              <img
                src={`/tokens/${
                  currentRandomToken?.name ||
                  lastDrawnTokenEvent?.drawnTokens.at(-1)?.name
                }.png`}
                alt="Ficha"
                className="w-20 object-contain"
              />
              {/* Nombre del token */}
              <div className="w-full text-center text-black text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                {currentRandomToken?.name ||
                  lastDrawnTokenEvent?.drawnTokens.at(-1)?.name}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Fila inferior */}
      <div className="relative flex mt-1 whitespace-nowrap">
        {lastDrawnTokenEvent?.hots && lastDrawnTokenEvent?.hots.length > 0 && `${lastDrawnTokenEvent?.hots.length} |`}
        <div className="flex overflow-x-scroll ml-1">
          {/* Jugadores Esperando */}
          {lastDrawnTokenEvent?.hots?.map((hot, index) => (
            <span
              key={index}
              className="flex items-center justify-center mx-2 text-sm"
            >
              <Lottie
                animationData={fireAnimation}
                loop={true}
                initialSegment={[0, 25]}
                className="w-4 mr-1"
              />
              {hot.playerName}({hot.cardId})
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DrawerVisor;
