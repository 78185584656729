import React, { useEffect, useState } from "react";
import Card from "../components/card";
import {
  IDrawnTokenEvent,
  IEarlyPayWinnerPlayer,
  IPlayerGame,
  IRound,
  ISerialWinnerPlayer,
  IToken,
} from "../common/interfaces";
import DrawerVisor from "../components/player/drawer_visor";
import Loading from "../components/loading";
import {
  fetchFromServer,
  preloadTokenImages,
  speak,
} from "../common/functions";
import { Helmet } from "react-helmet";
import SerialWinnerAnimation from "../components/serial-winner-animation";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import MobileButtons from "../components/mobile_buttons";
import LotteryInfoColumn from "../components/lottery-info.column";
import WinnersAnimation from "../components/card-winners-animation";
import CardWinnerAnimation from "../components/player/card-winner-animation";
import PlayerRightColumn from "../components/player/player-right-column";
import { useSocket } from "../common/contexts/useSocket";
import EarlyPayWinnerAnimation from "../components/earlypay-winner-animation";

const PlayerPage: React.FC = () => {
  const { userId: playerId } = useOutletContext<{ userId: number }>();
  const { lotteryId } = useParams();
  const [allTokens, setAllTokens] = useState<IToken[]>([]);
  const [isLeftColumnVisible, setisLeftColumnVisible] = useState(false);
  const [isRightColumnVisible, setisRightColumnVisible] = useState(false);
  const [playerGame, setPlayerGame] = useState<IPlayerGame>();
  const [currentRound, setCurrentRound] = useState<IRound>();
  const [isCardWinner, setIsCardWinner] = useState<boolean>();
  const [serialWinnerJustDrawn, setSerialWinnerJustDrawn] =
    useState<boolean>(false);
  const [earlyPayWinnerJustDrawn, setEarlyPayWinnerJustDrawn] =
    useState<boolean>(false);
  const [thereAreUnseenMessages, setThereAreUnseenMessages] = useState(false);
  const { socket, connectionStatus } = useSocket();

  const navigate = useNavigate();

  const [lastDrawnTokenEvent, setLastDrawnTokenEvent] =
    useState<IDrawnTokenEvent>();

  const openLeftColumn = () => {
    setisLeftColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  };

  const openRightColumn = () => {
    setisRightColumnVisible(true);
    window.history.pushState(null, "", window.location.href);
  };

  const handleLogout = async () => {
    try {
      const response = await fetchFromServer(
        "POST",
        `/auth/player/logout`,
        undefined,
        "player",
        navigate,
      );

      if (response.ok) {
        navigate(`/loteria/${lotteryId}`);
        localStorage.removeItem("player_accessToken");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const fetchAllTokens = async () => {
    const response = await fetchFromServer("GET", `/tokens/all`);

    if (!response.ok) {
      throw new Error("Error en la solicitud");
    }

    const tokens: IToken[] = await response.json();

    return tokens;
  };

  const fetchGame = async () => {
    const response = await fetchFromServer(
      "GET",
      `/lotteries/mygame`,
      undefined,
      "player",
      navigate,
    );

    if (!response.ok) throw new Error();

    const playerGame: IPlayerGame = await response.json();

    return playerGame;
  };

  const nextRound = () => {
    // Actualizamos las victorias en el round y el currentRoundId.
    setPlayerGame((prevPlayerGame) => {
      if (prevPlayerGame) {
        const rounds = prevPlayerGame.lottery.rounds!;
        const currentIndex = rounds.findIndex(
          (round) => round.id === currentRound?.id,
        );

        const updatedRounds = prevPlayerGame.lottery.rounds.map((round) =>
          round.id === lastDrawnTokenEvent?.roundId
            ? { ...round, victories: lastDrawnTokenEvent.winners }
            : round,
        );

        const newCurrentRoundId =
          currentIndex !== -1 && currentIndex < rounds.length - 1
            ? rounds[currentIndex + 1].id
            : undefined;

        const newCurrentRound = prevPlayerGame.lottery.rounds.find(
          (round) => round.id === newCurrentRoundId,
        );
        setCurrentRound(newCurrentRound);

        return {
          ...prevPlayerGame,
          lottery: {
            ...prevPlayerGame.lottery,
            currentRoundId: newCurrentRoundId,
            rounds: updatedRounds,
          },
        };
      }
    });

    setLastDrawnTokenEvent(undefined);
  };

  useEffect(() => {
    const onStart = async (data: any) => {
      const { startDate } = data;

      setPlayerGame((prevPlayerLottery) => {
        if (prevPlayerLottery) {
          const newCurrentRound = prevPlayerLottery.lottery.rounds.find(
            (round) => round.victories.length === 0,
          );
          setCurrentRound(newCurrentRound);

          return {
            ...prevPlayerLottery,
            lottery: {
              ...prevPlayerLottery.lottery,
              startDate: startDate,
              currentRoundId: newCurrentRound?.id,
            },
          };
        }

        return prevPlayerLottery; // En caso de que sea undefined, devolvemos el estado previo
      });
      speak("La lotería ha comenzado.");
    };

    const onTokenDrawn = async (drawnTokenEvent: IDrawnTokenEvent) => {
      // Actualizamos los drawnTokens del round.
      setPlayerGame((prevPlayerGame) => {
        if (prevPlayerGame) {
          const updatedRounds = prevPlayerGame.lottery.rounds.map((round) =>
            round.id === drawnTokenEvent.roundId
              ? {
                  ...round,
                  drawnTokens: drawnTokenEvent.drawnTokens,
                  victories: drawnTokenEvent.winners,
                }
              : round,
          );

          const _currentRound = updatedRounds.find(
            (round) => round.id === drawnTokenEvent.roundId,
          );

          // Retardamos esta asignacion para que la ficha se marque luego de que el visor muestre la nueva ficha sorteada
          setTimeout(() => {
            setCurrentRound(_currentRound);
          }, 2300);

          return {
            ...prevPlayerGame,
            lottery: {
              ...prevPlayerGame.lottery,
              rounds: updatedRounds,
              endDate: drawnTokenEvent.endDate
                ? drawnTokenEvent.endDate
                : prevPlayerGame.lottery.endDate,
            },
          };
        }
      });

      setLastDrawnTokenEvent(drawnTokenEvent);
    };

    const onSerialWinner = async (serialWinnerPlayer: ISerialWinnerPlayer) => {
      // Actualizamos el ganador de Serial Ganador.
      setPlayerGame((prevPlayerGame) => {
        if (prevPlayerGame) {
          setSerialWinnerJustDrawn(true);
          return {
            ...prevPlayerGame,
            lottery: {
              ...prevPlayerGame.lottery,
              cardSerialWinner: serialWinnerPlayer,
            },
          };
        }
      });
    };

    const onEarlyPayWinner = async (
      earlyPayWinnerPlayer: IEarlyPayWinnerPlayer,
    ) => {
      // Actualizamos el ganador de Serial Ganador.
      setPlayerGame((prevPlayerGame) => {
        if (prevPlayerGame) {
          setEarlyPayWinnerJustDrawn(true);
          return {
            ...prevPlayerGame,
            lottery: {
              ...prevPlayerGame.lottery,
              earlyPayDrawWinner: earlyPayWinnerPlayer,
            },
          };
        }
      });
    };

    const mountGame = async () => {
      const _allTokens = await fetchAllTokens();
      await preloadTokenImages(_allTokens);
      setAllTokens(_allTokens);

      const _playerGame = await fetchGame();
      const newCurrentRound = _playerGame.lottery.rounds.find(
        (round) => round.id === _playerGame.lottery.currentRoundId,
      );
      setCurrentRound(newCurrentRound);
      setPlayerGame(_playerGame);

      if (_playerGame.lottery.live) {
        socket.on("start", onStart);
        socket.on("serialWinner", onSerialWinner);
        socket.on("earlyPayWinner", onEarlyPayWinner);
        socket.on("tokenDrawn", onTokenDrawn);
        socket.auth = {
          accessToken: localStorage.getItem("player_accessToken"),
        };
        socket.connect();
      }
    };

    mountGame();

    return () => {
      socket.disconnect();
      socket.off("start", onStart);
      socket.off("tokenDrawn", onTokenDrawn);
      socket.off("serialWinner", onSerialWinner);
      socket.off("earlyPayWinner", onEarlyPayWinner);
    };
  }, []);

  useEffect(() => {
    // Agregar una entrada inicial al historial
    window.history.pushState(null, "", window.location.href);

    const handlePopState = () => {
      // Si no hay columnas abiertas, rehacer el estado actual
      if (!isLeftColumnVisible && !isRightColumnVisible) {
        window.history.pushState(null, "", window.location.href);
        return;
      }

      // Si hay una columna abierta, replegarla
      if (isLeftColumnVisible) {
        setisLeftColumnVisible(false);
      } else if (isRightColumnVisible) {
        setisRightColumnVisible(false);
      }
    };

    // Escucha el evento de retroceso del historial
    window.addEventListener("popstate", handlePopState);

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isLeftColumnVisible, isRightColumnVisible]);

  if (!playerGame) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-dvh">
      <Helmet>
        <title>
          Lotería {playerGame.lottery.name} - Impulsado por Guacamayalote.online
        </title>
      </Helmet>
      {serialWinnerJustDrawn && (
        <SerialWinnerAnimation
          playerId={playerId}
          serialWinnerPlayer={playerGame.lottery.cardSerialWinner}
        />
      )}
      {earlyPayWinnerJustDrawn && (
        <EarlyPayWinnerAnimation
          playerId={playerId}
          earlyPayWinnerPlayer={playerGame.lottery.earlyPayDrawWinner}
          earlyPayAward={playerGame.lottery.earlyPayDrawAward}
        />
      )}
      {isCardWinner && (
        <CardWinnerAnimation
          lastDrawnTokenEvent={lastDrawnTokenEvent!}
          currentRound={currentRound!}
          nextRound={nextRound}
          setIsCardWinner={setIsCardWinner}
        />
      )}
      {isCardWinner === false && (
        <WinnersAnimation
          lastDrawnTokenEvent={lastDrawnTokenEvent!}
          currentRound={currentRound!}
          nextRound={nextRound}
          setIsCardWinner={setIsCardWinner}
        />
      )}
      {/* Botones flotantes para móviles */}
      <MobileButtons
        openLeftColumn={openLeftColumn}
        openRightColumn={openRightColumn}
        isOrganizer={false}
        thereAreUnseenMessages={thereAreUnseenMessages}
        className={`xl:hidden fixed bottom-4 right-4 z-30 flex flex-col space-y-2 transition-opacity duration-300 ${
          !isLeftColumnVisible && !isRightColumnVisible
            ? "opacity-100 visible"
            : "opacity-0 invisible"
        }`}
      />

      {/* Estructura de columnas */}
      <div className="flex flex-1 flex-row">
        {/* Columna izquierda */}
        <LotteryInfoColumn
          lottery={playerGame.lottery}
          currentRound={currentRound}
          isVisible={isLeftColumnVisible}
          setIsVisible={setisLeftColumnVisible}
        />

        {/* Contenido central */}
        <div
          className="w-full relative xl:w-1/2 xl:ml-[25%]"
          style={{
            background: "linear-gradient(to right, #33FCFF, #5CADFF)",
          }}
        >
          {/* Fila superior fija dentro de la columna central */}
          <div
            className="sticky top-0 w-full text-white p-2 mb-4 z-10"
            style={{
              background: "linear-gradient(to right, #00CED1, #1E90FF)", // Gradiente
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="flex p-1 justify-between items-center">
              <span>{playerGame.player.name}</span>
              <button
                onClick={handleLogout}
                className="text-red-500 hover:text-red-700 font-bold"
              >
                Salir
              </button>
            </div>
            <DrawerVisor
              playerId={playerId}
              tokens={allTokens}
              lastDrawnTokenEvent={lastDrawnTokenEvent}
              currentRound={currentRound}
              setIsCardWinner={setIsCardWinner}
            />
          </div>

          {/* Contenido principal */}
          <div className="my-4 flex justify-center items-center flex-wrap gap-4">
            {playerGame.myCards.map((card) => (
              <Card
                key={card.serial}
                card={card}
                winningCombination={
                  currentRound?.winningCombination.combination
                }
                drawnTokens={currentRound?.drawnTokens}
                showSerial={true}
              />
            ))}
          </div>
        </div>

        {/* Columna derecha */}
        <PlayerRightColumn
          isVisible={isRightColumnVisible}
          setIsVisible={setisRightColumnVisible}
          setThereAreUnseenMessages={setThereAreUnseenMessages}
        />
      </div>
    </div>
  );
};

export default PlayerPage;
