import React, { useEffect } from "react";
import { ILottery, IRound } from "../common/interfaces";
import Card from "./card";
import { FaWhatsapp } from "react-icons/fa";
import WinningExampleCard from "./winning_example_card";
import { CiWarning } from "react-icons/ci";
import { currencies } from "../common/consts";
const { DateTime } = require("luxon");

interface MyComponentProps {
  lottery: ILottery;
  currentRound?: IRound;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LotteryInfoColumn: React.FC<MyComponentProps> = ({
  lottery,
  currentRound,
  isVisible,
  setIsVisible,
}) => {
  useEffect(() => {
    if (isVisible) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling
      document.body.style.overflow = "auto";
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  return (
    <div
      className={`bg-black/50 xl:bg-turquoise-500 w-full xl:w-1/4 h-full fixed top-0 left-0 z-30 transition-transform duration-300 ${
        isVisible || "xl:translate-x-0 -translate-x-full"
      }`}
      style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
    >
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-2 right-2 w-8 h-8 bg-red-500 text-white rounded-full xl:hidden z-30"
      >
        ×
      </button>
      <div className="p-4 overflow-y-auto h-full">
        <div className="space-y-2">
          <div className="p-4 bg-gray-50 rounded-lg shadow-md max-w-md mx-auto">
            {/* Encabezado principal */}
            <h2 className="text-xl font-bold text-center text-dodgerBlue-500 mb-6">
              Lotería
              <br />
              <span className="text-2xl font-bold">{lottery.name}</span>
            </h2>

            {/* Información General */}
            <div className="space-y-3">
              <div className="flex justify-between">
                <span className="font-bold text-gray-700">
                  Precio del Cartón:
                </span>
                <span>
                  {lottery.cardPrice}{" "}
                  {
                    currencies.find(
                      (currency) => currency.code === lottery.currency,
                    )?.name
                  }
                </span>
              </div>
              {lottery.soldCardsQty && (
                <div className="flex justify-between">
                  <span className="font-bold text-gray-700">
                    Cartones Vendidos:
                  </span>
                  <span>{lottery.soldCardsQty}</span>
                </div>
              )}
              <div>
                <span className="font-bold text-gray-700">
                  Fecha del Sorteo:
                </span>
                <span className="block text-gray-600">
                  {lottery.drawDate
                    ? DateTime.fromISO(lottery.drawDate)
                        .setLocale("es")
                        .toFormat("EEEE, d LLL yyyy h:mm a")
                    : "N/A"}
                </span>
              </div>
              <div>
                <span className="font-bold text-gray-700">
                  Fecha de Inicio:
                </span>
                <span className="block text-gray-600">
                  {lottery.startDate
                    ? DateTime.fromISO(lottery.startDate)
                        .setLocale("es")
                        .toFormat("EEEE, d LLL yyyy h:mm a")
                    : "No ha comenzado"}
                </span>
              </div>
              <div>
                <span className="font-bold text-gray-700">Fecha de Fin:</span>
                <span className="block text-gray-600">
                  {lottery.endDate
                    ? DateTime.fromISO(lottery.endDate)
                        .setLocale("es")
                        .toFormat("EEEE, d LLL yyyy h:mm a")
                    : "No ha terminado"}
                </span>
              </div>
            </div>

            {/* Organizador */}
            <div className="mt-6 border-t border-gray-300 pt-4">
              <h3 className="text-lg font-bold text-gray-700 mb-2">
                Organizador
              </h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Nombre:</span>
                  <span>{lottery.organizer.name || "N/A"}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Email:</span>
                  <span>{lottery.organizer.email || "N/A"}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Teléfono:</span>
                  <span>
                    {lottery.organizer.phone ? (
                      <a
                        href={`https://wa.me/${lottery.organizer.phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 flex items-center gap-2 hover:underline"
                      >
                        <FaWhatsapp className="text-green-500" />{" "}
                        {lottery.organizer.phone}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                <div className="bg-yellow-500 m-2 p-2 rounded-lg">
                  Asegúrate de que el organizador de esta lotería sea una
                  persona o entidad de tu confianza.
                </div>
              </div>
            </div>

            {/* Informacion Pronto Pago */}
            {lottery.earlyPayDraw && (
              <div className="mt-6 border-t border-gray-300 pt-4">
                <h3 className="text-lg font-bold text-gray-700 mb-2">
                  Sorteo de Pronto Pago
                </h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="font-semibold text-gray-700">Premio:</span>
                    <span>{lottery.earlyPayDrawAward || "N/A"}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-semibold text-gray-700">
                      Fecha Limite:
                    </span>
                    <span>
                      {lottery.earlyPayDrawLimitDate
                        ? DateTime.fromISO(lottery.earlyPayDrawLimitDate)
                            .setLocale("es")
                            .toFormat("EEEE, d LLL yyyy")
                        : "N/A"}
                    </span>
                  </div>
                  {lottery.earlyPayDrawWinner && (
                    <div className="flex justify-between flex-col">
                      <span className="font-semibold text-gray-700">
                        Ganador:
                      </span>
                      <span className="ml-4">
                        {lottery.earlyPayDrawWinner.name} (ID:{" "}
                        {lottery.earlyPayDrawWinner.id})
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Informacion Sorteo de Serial*/}
            {lottery.cardSerialDraw && (
              <div className="mt-6 border-t border-gray-300 pt-4">
                <h3 className="text-lg font-bold text-gray-700 mb-2">
                  Sorteo de Serial
                </h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="font-semibold text-gray-700">Premio:</span>
                    <span>{lottery.cardSerialDrawAward || "N/A"}</span>
                  </div>
                  {lottery.cardSerialWinner && (
                    <div className="flex justify-between flex-col">
                      <span className="font-semibold text-gray-700">
                        Ganador:
                      </span>
                      <span className="ml-4">
                        {lottery.cardSerialWinner.card.serial} (
                        {lottery.cardSerialWinner.name})
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="mt-6 p-4 bg-gray-50 rounded-lg shadow-md max-w-md mx-auto">
            {/* Encabezado de Rondas */}
            <h3 className="text-2xl font-bold text-center text-dodgerBlue-500 mb-4">
              Rondas
            </h3>

            {/* Lista de Rondas */}
            <ul className="space-y-4">
              {lottery.rounds.map((round, index) => (
                <li
                  key={round.id}
                  className={`border-2 p-4 rounded-lg shadow-sm transition-all duration-300 ${
                    round.id === currentRound?.id
                      ? "bg-yellow-200 border-yellow-400"
                      : "bg-white border-gray-200"
                  }`}
                >
                  {/* Título de Ronda */}
                  <div className="mb-2 flex justify-between items-center">
                    <h4 className="text-lg font-bold text-gray-700">
                      Ronda {index + 1}
                    </h4>
                    {round.id === currentRound?.id && (
                      <span className="text-xs font-semibold text-yellow-700 bg-yellow-100 px-2 py-1 rounded">
                        Actual
                      </span>
                    )}
                  </div>

                  {/* Información de la Ronda */}
                  <div className="space-y-1 text-gray-600">
                    <p>
                      <strong>Premio:</strong> {round.award}
                    </p>
                    <p>
                      <strong>Combinación Ganadora:</strong>{" "}
                      {round.winningCombination.name}
                    </p>
                  </div>

                  {/* Victorias */}
                  {round.victories.length > 0 ? (
                    <div className="mt-4">
                      <p className="font-semibold text-gray-700 mb-2">
                        {round.victories.length === 1 ? "Ganador" : "Ganadores"}
                      </p>
                      <ul className="ml-4 list-disc space-y-2">
                        {round.victories.map((victory, idx) => (
                          <li key={idx} className="flex flex-col space-y-1">
                            <p className="text-gray-600">{victory.name}</p>
                            <Card
                              card={victory.cards[0]}
                              showSerial={true}
                              winningCombination={
                                round.winningCombination.combination
                              }
                              drawnTokens={round.drawnTokens}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <WinningExampleCard
                        winningCombination={
                          round.winningCombination.combination
                        }
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LotteryInfoColumn;
