import "./App.css";
import {
  Route,
  Navigate,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import PlayerPage from "./pages/player";
import LotteryEnter from "./pages/player_enter";
import OrganizerLogin from "./pages/organizer_login";
import CreateLotteryPage from "./pages/create-lottery";
import LotteriesPage from "./pages/lotteries";
import SellCardsPage from "./pages/sell-cards";
import ShowcasePage from "./pages/showcase";
import Auth from "./components/Auth";
import StagePage from "./pages/stage";
import SearchPlayerPage from "./pages/search-player";
import UnderConstructionPage from "./pages/under_construction";
import RegisterPage from "./pages/register";
import SellersPage from "./pages/sellers";

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas protegidas del organizador */}
        <Route
          path="/organizador/*"
          element={
            <Routes>
              <Route path="/" element={<Auth role={"organizer"} />}>
                <Route path="loterias" element={<LotteriesPage />} />
                <Route path="vendedores" element={<SellersPage />} />
                <Route path="loterias/crear" element={<CreateLotteryPage />} />
                <Route
                  path="loteria/:lotteryId/tarima"
                  element={<StagePage />}
                />
                <Route
                  path="loteria/:lotteryId/venderCartones"
                  element={<SellCardsPage />}
                />
                <Route
                  path="loteria/:lotteryId/vendedores"
                  element={<SellersPage />}
                />
                <Route
                  path="loteria/:lotteryId/buscarJugador"
                  element={<SearchPlayerPage />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/organizador/login" />} />
            </Routes>
          }
        />

        {/* Rutas protegidas del jugador */}
        <Route
          path="/jugador/*"
          element={
            <Routes>
              <Route path="/" element={<Auth role={"player"} />}>
                <Route
                  path="loteria/:lotteryId/mesa"
                  element={<PlayerPage />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/organizador/login" />}
                />
              </Route>
            </Routes>
          }
        />

        {/* Rutas pubicas */}
        <Route path="/" element={<Navigate to="/organizador/login" />} />
        <Route
          path="/preguntas-frecuentes"
          element={<UnderConstructionPage />}
        />
        <Route
          path="/terminos-y-condiciones"
          element={<UnderConstructionPage />}
        />
        <Route path="/contacto" element={<UnderConstructionPage />} />
        <Route path="/loteria/:lotteryId/entrar" element={<LotteryEnter />} />
        <Route
          path="/loteria/:lotteryId/entrar/:passFromUrl"
          element={<LotteryEnter />}
        />
        <Route path="/loteria/:lotteryId" element={<ShowcasePage />} />
        <Route path="/organizador/registrarse" element={<RegisterPage />} />
        <Route path="/organizador/login" element={<OrganizerLogin />} />
        <Route path="*" element={<Navigate to="/organizador/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
