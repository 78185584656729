import { ILottery, IToken } from "./interfaces";
import { Role } from "./types";
import { NavigateFunction } from "react-router-dom";

// Precargar imágenes
export const preloadTokenImages = async (tokens: IToken[]) => {
  const tokenUrls = tokens.map((token) => "/tokens/" + token.name + ".png");
  const promises = tokenUrls.map((url) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
    });
  });

  await Promise.all(promises);
};

export const fetchFromServer = async (
  method: "GET" | "POST" | "PUT" | "DELETE",
  endpoint: string,
  body?: any,
  role?: Role,
  navigate?: NavigateFunction,
) => {
  try {
    const headers: { "Content-Type": string; Authorization?: string } = {
      "Content-Type": "application/json",
    };

    if (role) {
      headers.Authorization = `Bearer ${localStorage.getItem(`${role}_accessToken`)}`;
    }

    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: method,
      body:
        method === "GET"
          ? undefined
          : JSON.stringify(body, (key, value) => {
              // Omitir valores vacíos (null, undefined o cadenas vacías)
              if (value === "" || value === null || value === undefined) {
                return undefined; // Si el valor es vacío, omítelo
              }
              return value; // De lo contrario, mantén el valor
            }),
      credentials: role ? "include" : "omit",
      headers: headers,
    });

    if (response.status === 401) {
      localStorage.removeItem(`${role}_accessToken`);
      if (navigate) {
        if (role === "organizer") {
          navigate("/organizador/login");
        } else if (role === "player") {
          navigate("/");
        }
      }
    }

    return response;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
};

export const speak = (text: string) => {
  window.speechSynthesis.cancel();
  const utterance = new SpeechSynthesisUtterance(text);
  utterance.lang = "es-ES";
  console.log(`Speak: ${text}`);
  window.speechSynthesis.speak(utterance);
};

export const handleSharePass = async (lottery: ILottery, pass: string) => {
  if (navigator.share) {
    // Verifica si la API de Web Share está disponible
    try {
      await navigator.share({
        title: `Loteria ${lottery.name}`,
        text: `Gracias por participar en lotería ${lottery.name}. Tu pase es: ${pass} y puedes acceder desde: `,
        url: `https://guacamayalote.online/loteria/${lottery.id}/entrar/${pass}`, // URL que se va a compartir
      });
    } catch (error) {
      console.error("Error al compartir:", error);
    }
  } else {
    alert(
      "La funcionalidad de compartir no está disponible en este dispositivo.",
    );
  }
};

export const handleShareShowcase = async (lottery: ILottery) => {
  if (navigator.share) {
    // Verifica si la API de Web Share está disponible
    try {
      await navigator.share({
        title: `Loteria ${lottery.name}`,
        text: `¡Échale un ojo a lotería ${lottery.name}! Elige los cartones que te gusten y comunícate con el organizador. `,
        url: `https://guacamayalote.online/loteria/${lottery.id}`,
      });
    } catch (error) {
      console.error("Error al compartir:", error);
    }
  } else {
    alert(
      "La funcionalidad de compartir no está disponible en este dispositivo.",
    );
  }
};
