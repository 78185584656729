import React from "react";
import { ICard, IToken } from "../common/interfaces";
import markAnimation from "../lottie-animations/x-marker.json";
import Lottie from "lottie-react";

interface MyComponentProps {
  card: ICard;
  drawnTokens?: IToken[];
  winningCombination?: number[];
  showSerial: boolean;
}

const Card: React.FC<MyComponentProps> = ({
  card,
  drawnTokens,
  winningCombination,
  showSerial,
}) => {
  var localCard = {
    ...card,
    tokens: card.tokens.map((token) => ({
      ...token,
      drawnDate:
        drawnTokens?.find((dt) => dt.id === token.id)?.drawnDate || null,
    })),
  };

  const rows = [];
  for (let i = 0; i < localCard.tokens.length; i += 5) {
    rows.push(localCard.tokens.slice(i, i + 5));
  }

  return (
    <div className="flex flex-col items-center">
      {/* Serial centrado y en negritas */}
      {showSerial && (
        <div className="text-center">
          <span className="font-bold text-sm">Serial: {card.serial}</span>
        </div>
      )}

      {/* Cartón */}
      <div
        className="flex-none w-[280px] lg:w-[350px] max-w-[100%] grid place-items-center rounded-lg"
        style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", color: "black" }}
      >
        <div className="grid grid-cols-5 gap-1 p-1 bg-white border-gray-200 rounded-lg">
          {rows.flat().map((token, index) => (
            <div
              key={index}
              className={`relative flex flex-col items-center justify-center border p-2 border-gray-400 rounded-lg ${
                winningCombination?.includes(index) || !winningCombination
                  ? "bg-white"
                  : "bg-neutral-300"
              }`}
            >
              {/* Imagen del token */}
              <img
                src={`/tokens/${token.name}.png`}
                alt={`Ficha ${token.name}`}
                className="w-14"
                onDragStart={(e) => e.preventDefault()}
                onMouseDown={(e) => e.preventDefault()}
              />

              {/* Nombre del token */}
              <div className="w-full text-center text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                {token.name}
              </div>

              {/* Marcar ficha si existe drawnDate */}
              {token.drawnDate && (
                <Lottie
                  animationData={markAnimation}
                  loop={false}
                  className="absolute w-10"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
