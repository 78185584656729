import React, { useState, useRef, useEffect } from "react";
import { IDrawnTokenEvent, ILottery, IToken } from "../../common/interfaces";
import { speak } from "../../common/functions";
import fireAnimation from "../../lottie-animations/hot.json";
import Lottie from "lottie-react";
import { useSocket } from "../../common/contexts/useSocket";

interface MyComponentProps {
  lottery: ILottery;
  tokens: IToken[];
  lastDrawnTokenEvent?: IDrawnTokenEvent;
}

const Drawer: React.FC<MyComponentProps> = ({
  lottery,
  tokens,
  lastDrawnTokenEvent,
}) => {
  const [isAuto, setIsAuto] = useState(false);
  const isDrawingRef = useRef(false);
  const [currentRandomToken, setCurrentRandomToken] = useState<IToken | null>(
    null,
  );
  const shufflerRef = useRef<NodeJS.Timeout | null>(null);
  const { socket, connectionStatus } = useSocket();

  const speech = (drawnTokenEvent: IDrawnTokenEvent) => {
    if ("speechSynthesis" in window) {
      const lastDrawnToken = drawnTokenEvent.drawnTokens.at(-1);

      if (lastDrawnToken) {
        var speech = lastDrawnToken.name;

        if (drawnTokenEvent.winners.length > 0) {
          speech += ", y han cantado lotería. ";

          if (drawnTokenEvent.winners.length === 1) {
            speech +=
              drawnTokenEvent.winners[0].name +
              " ha ganado con el cartón número " +
              drawnTokenEvent.winners[0].cards[0].serial +
              ". ";
          } else if (drawnTokenEvent.winners.length > 1) {
            speech += "Hay " + drawnTokenEvent.winners.length + " ganadores. ";

            for (var i = 0; i < drawnTokenEvent.winners.length - 1; i++) {
              speech +=
                drawnTokenEvent.winners[i].name +
                " ha ganado con el cartón número " +
                drawnTokenEvent.winners[i].cards[0].serial +
                ", ";
            }

            speech +=
              "y " +
              drawnTokenEvent.winners.at(-1)?.name +
              " ha ganado con el cartón número " +
              drawnTokenEvent.winners.at(-1)?.cards[0].serial +
              ". ";
          }

          if (drawnTokenEvent.endDate) {
            speech += "La lotería ha terminado.";
          }
        }
        speak(speech);
      }
    }
  };

  const startFakeShuffler = () => {
    if (!shufflerRef.current) {
      shufflerRef.current = setInterval(() => {
        const notDrawnTokens = tokens.filter((token) => !token.drawnDate);
        const randomIndex = Math.floor(Math.random() * notDrawnTokens.length);
        setCurrentRandomToken(notDrawnTokens[randomIndex]);
      }, 100);
    }
  };

  const stopFakeShuffler = () => {
    if (shufflerRef.current) {
      clearInterval(shufflerRef.current);
      shufflerRef.current = null;
      setCurrentRandomToken(null);
    }
  };

  useEffect(() => {
    if (lastDrawnTokenEvent) {
      stopFakeShuffler();
      speech(lastDrawnTokenEvent);
      isDrawingRef.current = false;
      if (lastDrawnTokenEvent.winners.length === 0) {
        if (isAuto) {
          setTimeout(() => {
            handleDraw();
          }, 3000);
        }
      } else {
        setIsAuto(false);
      }
    }
  }, [lastDrawnTokenEvent]);

  const handleDraw = () => {
    if (
      lottery.startDate &&
      !lottery.endDate &&
      connectionStatus === "connected"
    ) {
      isDrawingRef.current = true;
      startFakeShuffler();
      socket.emit("draw");
    }
  };

  const handleDrawSerialWinner = () => {
    if (lottery.startDate && !lottery.endDate) {
      socket.emit("drawserial");
    }
  };

  const handleDrawearlyPay = () => {
    if (lottery.startDate && !lottery.endDate) {
      socket.emit("drawearlypay");
    }
  };

  return (
    <div className="flex flex-col">
      {/* Fila superior */}
      <div className="flex w-full">
        {/* Columna 1 */}
        <div className="w-1/2 flex flex-col items-center justify-center">
          <img
            src="/bag.png"
            alt="Bolsa de fichas"
            onClick={() => {
              if (!isAuto) {
                handleDraw();
              }
            }}
            className={`w-32 object-contain ${
              !isDrawingRef.current &&
              lottery.startDate &&
              !lottery.endDate &&
              connectionStatus === "connected"
                ? "animate-growShrink cursor-grab"
                : ""
            }`}
          />
          {lottery.startDate && connectionStatus === "connected" && (
            <div className="mt-4 flex items-center space-x-2">
              <input
                type="checkbox"
                id="auto-switch"
                checked={isAuto}
                onChange={(e) => {
                  if (e.target.checked && !isDrawingRef.current) {
                    handleDraw();
                  }
                  setIsAuto(e.target.checked);
                }}
                className="w-5 h-5 cursor-pointer accent-blue-500"
              />
              <label
                htmlFor="auto-switch"
                className="text-sm text-white cursor-pointer"
              >
                Automático
              </label>
            </div>
          )}
        </div>

        {/* Columna 2 */}
        <div className="w-1/2 flex items-center justify-center border-red-700 border-2 rounded-lg bg-white p-2">
          {(currentRandomToken || lastDrawnTokenEvent?.drawnTokens.at(-1)) && (
            <div>
              <img
                src={`/tokens/${
                  currentRandomToken?.name ||
                  lastDrawnTokenEvent?.drawnTokens.at(-1)?.name
                }.png`}
                alt="Ficha"
                className="w-20 object-contain"
              />
              {/* Nombre del token */}
              <div className="w-full text-center text-black text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                {currentRandomToken?.name ||
                  lastDrawnTokenEvent?.drawnTokens.at(-1)?.name}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Fila inferior */}
      <div className="relative flex mt-1 whitespace-nowrap">
        {lastDrawnTokenEvent?.hots && lastDrawnTokenEvent?.hots.length > 0 && `${lastDrawnTokenEvent?.hots.length} |`}
        <div className="flex overflow-x-scroll ml-1">
          {/* Jugadores Esperando */}
          {lastDrawnTokenEvent?.hots?.map((hot, index) => (
            <span
              key={index}
              className="flex items-center justify-center mx-2 text-sm"
            >
              <Lottie
                animationData={fireAnimation}
                loop={true}
                initialSegment={[0, 25]}
                className="w-4 mr-1"
              />
              {hot.playerName}({hot.cardId})
            </span>
          ))}
        </div>
      </div>

      {lottery.startDate &&
        lottery.cardSerialDraw &&
        !lottery.cardSerialWinner &&
        connectionStatus === "connected" && (
          <div className="flex justify-center mt-4">
            <button
              className="px-6 py-2 text-white font-bold rounded"
              style={{ backgroundColor: "#00CED1" }}
              onClick={(e) => {
                (e.target as HTMLButtonElement).style.display = "none";
                handleDrawSerialWinner();
              }}
            >
              Sortear Serial
            </button>
          </div>
        )}

      {lottery.startDate &&
        lottery.earlyPayDraw &&
        !lottery.earlyPayDrawWinner &&
        connectionStatus === "connected" && (
          <div className="flex justify-center mt-4">
            <button
              className="px-6 py-2 text-white font-bold rounded"
              style={{ backgroundColor: "#00CED1" }}
              onClick={(e) => {
                (e.target as HTMLButtonElement).style.display = "none";
                handleDrawearlyPay();
              }}
            >
              Sortear Pronto Pago
            </button>
          </div>
        )}
    </div>
  );
};

export default Drawer;
